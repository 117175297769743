import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  }).catch(err => console.error(err));

if (environment.production) {
  enableProdMode();
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/ngsw-worker.js', {
      updateViaCache: 'all'
    }).then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }).catch(error => {
      console.error('ServiceWorker registration failed: ', error);
    });
  });
} else {
  console.error("Service workers are not supported by this browser.");
}

//***********************************************
// scroll to top
//***********************************************
//  const toTop = document.querySelector(".scroll-to-top") as HTMLElement;
//  window.addEventListener("scroll", () => {
//    if (window.pageYOffset > 100) {
//      toTop.classList.add("active");
//    } else {
//      toTop.classList.remove("active");
//    }
//  });
//
// toTop.addEventListener("click", () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth"
//   });
// });
