import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeroTelephoneMobile'
})
export class NumeroTelephoneMobilePipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) return '';

    // Enlever tout sauf les chiffres
    const phone = value.toString().replace(/[^\d]/g, '');
    console.log("pipe cleaned" + phone);

    // Vérifier que le numéro a une longueur conforme
    if (phone.length !== 10) {
      console.warn('Numéro invalide :', phone);
      return phone; // Retourner brut si invalide
    }

    if (phone.length < 4)
      return phone; // Si le numéro est trop court, on le retourne tel quel

    if (phone.length < 6)
      return `${phone.slice(0, 4)}/${phone.slice(4)}`; // Si le numéro est de la forme xxxx/xx

    if (phone.length < 8)
      return `${phone.slice(0, 4)}/${phone.slice(4, 6)}.${phone.slice(6)}`; // Si le numéro est de la forme xxxx/xx.xx

    console.log("pipe phone complete" + phone + "return" + `${phone.slice(0, 4)}/${phone.slice(4, 6)}.${phone.slice(6, 8)}.${phone.slice(8)}`);

    // Formate le numéro en xxxx/xx.xx.xx
    return `${phone.slice(0, 4)}/${phone.slice(4, 6)}.${phone.slice(6, 8)}.${phone.slice(8)}`;
  }

}
