import {Week} from "../../../models/Week";
import {StageCard} from "../../../models/StageCard";

import * as G from './StagesGlobalValues';
import {AgeRange} from "../../common/age-range/age-range";

export class StagesModel {
  periods               :Map<string, boolean>   = new Map<string, boolean>();
  weeks                 :Map<Week, boolean>     = new Map(); // observé par le setter
  weekPlaces            :Map<string, string[]>  = new Map;
  places                :Map<string, boolean>   = new Map();
  filteredStages        :StageCard[]            = [];
  ageRange              :AgeRange               = new AgeRange(G.AGE_MIN, G.AGE_MAX, G.AGE_MIN, G.AGE_MAX);
  isAutoApplyFilters    :boolean                = false;
  // Il y a une différence avec this.weeks, stageDates représente la date de chaque stage, weeks sont les semaines qui comtienent ces dates.
  stagesDates           :string[]               = [];
  coursesCalledByButton :boolean                = false;
  /**
   * Vérifie si les filtres sont cohérents et peuvent être appliqués
   */
  isValidFilter(): boolean {
    return [...this.places.values()].filter(v => v).length !== 0;
  }

  /**
   * Vérifie si le filtre de période est valide.
   */
  isPeriodFilterValid(): boolean {
    return this.periods.size > 0; // Vérifie si au moins une période est sélectionnée
  }

  /**
   * Vérifie si le filtre de semaines est valide.
   */
  isWeeksFilterValid(): boolean {
    return this.weeks.size > 0; // Vérifie si au moins une semaine est sélectionnée
  }

  /**
   * S'il n'y a qu'une seule période, elle sera automatiquement sélectionnée.
   */
  selectTheOnlyPeriodIfTrue() {
    //console.log(this.periods)
    if (this.periods.size === 1) {
      this.periods.forEach((v, k) => this.periods.set(k, true))
    }
    //console.log(this.periods)
  }
}
