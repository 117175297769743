<header class="main_bar">
  <button (click)="goToHome()">
    <img src="{{this.image}}" id="image_logo" alt="logo">
  </button>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="user_account"><i class="fa-solid fa-user fa-xl m-auto"></i></button>


  <!--  <i class="fa-solid fa-xmark fa-2xl"></i>-->

  <mat-menu #menu="matMenu" class="mat-menu-panel">
<!--        <button *ngIf="!this.isMainRoot()" mat-menu-item (click)="goToHome()"><i class="fa-solid fa-house"></i> Home</button>-->
        <button *ngIf="isNaN(this.connectedAccount.numero_compte)" mat-menu-item (click)="goToConnection()"><i class="fa-solid fa-arrow-right-to-bracket"></i>    Connexion</button>
        <button *ngIf="isNaN(this.connectedAccount.numero_compte)" mat-menu-item (click)="goToInscription()"><i class="fa-solid fa-user-plus"></i>    Inscription</button>
        <button *ngIf="!isNaN(this.connectedAccount.numero_compte)" mat-menu-item (click)="goToAcount()"><i class="fa-solid fa-user"></i> {{this.connectedAccount.nom }} <br> {{ this.connectedAccount.prenom}}</button>
        <button *ngIf="!isNaN(this.connectedAccount.numero_compte)" mat-menu-item (click)="disconnect()"><i class="fa-solid fa-right-from-bracket"></i> Déconnexion</button>
        <!-- Add more menu items as needed -->
    </mat-menu>
</header>
