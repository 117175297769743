import {debounce, debounceTime, filter, Observer, Subject, tap} from "rxjs";
import {StagesFiltersLocalStorage} from "./StagesFiltersLocalStorage";
import {convertDateFormat, getIntervalDates, getSelectionableWeeks, Week} from "../../../models/Week";
import {StagesComponent} from "./stages.component";
import * as he from "he";
import {StageCard} from "../../../models/StageCard";
import {getPeriodeByDate} from "../../../models/PeriodeDate";
import {StagesEvent} from "./StagesEvent";
import {UselessLoadingSkipper} from "../../../models/UselessLoadingSkipper";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
import {AgeRange} from "../../common/age-range/age-range";
import * as G from './StagesGlobalValues';
import {StagesModel} from "./StagesModel";
import {DataService} from "../../../service/data.service";
import {Paginator} from "../../../models/Paginator";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus
;

export class StagesObserverUtil {

  /**
   * Initialise un observable qui observe les sélections des périodes par l'utilisateur et envoie des requêtes pour
   * charger les semaines, avec un petit délai pour éviter l'envoie plusieurs requêtes aux cliques rapides.
   * L'etat de la vue est mise à jour pour afficher le chargement, données, messages. Après l'évènement, les semaines
   * sont chargées et les données locales et attributs sont mises à jour.
   * @private
   */
  static initPeriodObserver(this: StagesComponent) {
    // Objet fonctionnant un peu comme un sémaphore pour supprimer le chargement lors d'une désélection
    let skipper: UselessLoadingSkipper = new UselessLoadingSkipper(G.REQUEST_DELAY)

    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE Semaines
    this.Stream.periods$ = new Subject<{key: string, value: boolean}>().pipe(
      tap((v) => {
        // 1. définition du status skip (pour savoir s'il faut passer le chargement)
        skipper.defineSkipStatus(v);

        // 2. pas besoin de chargement lors d'une désélection
        if (v.value) {
          this.loadingStatus.week = LOADING;
        }

        // 3. mise à jours de l'attribut
        this.Model.periods.set(v.key, v.value);

        // 4. enregistrement du filtre en local
        StagesFiltersLocalStorage.saveToLocal({periodes: this.Model.periods});

        // 5. nettoyage des stages s'il n'y a pas de sélection (pour éviter des bugs d'affichage)
        let selectedPeriodes = [...this.Model.periods.values()].some(v => v)
        if (!selectedPeriodes) {
          this.clearStages();
        }
      }),
      debounce(skipper.timer),
    );

    this.Stream.periods$.subscribe((value: {key: string, value: boolean}) => {
      let skipLoading = skipper.endSkipStatus(value);

      StagesEvent.onPeriodClick.call(this, value.key, value.value, skipLoading).then()
    })
  }

  /**
   * Initialise un observable qui observe les sélections des semaines par l'utilisateur et envoie des requêtes pour
   * charger les lieux, avec un petit délai pour éviter l'envoie plusieurs requêtes aux cliques rapides.
   * L'etat de la vue est mise à jour pour afficher le chargement, données, messages. Après l'évènement, les lieux
   * sont chargées et les données locales et attributs sont mises à jour.
   * @private
   */
  static initWeekObserver(this: StagesComponent) {
    // Objet fonctionnant un peu comme un sémaphore pour supprimer le chargement lors d'une désélection
    let skipper: UselessLoadingSkipper = new UselessLoadingSkipper(G.REQUEST_DELAY)

    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE Semaines
    this.Stream.weeks$ = new Subject<{key: Week, value: boolean}>().pipe(
      tap((v) => {
        // 1. définition du status skip (pour savoir s'il faut passer le chargement)
        skipper.defineSkipStatus(v);

        // 2. pas besoin de chargement lors d'une désélection
        if (v.value) {
          this.loadingStatus.place = LOADING;
        }

        // 3. mise à jours de l'attribut
        this.Model.weeks.set(v.key, v.value);

        // 4. enregistrement du filtre en local
        StagesFiltersLocalStorage.saveToLocal({weeks: this.Model.weeks});

        // 5. nettoyage des stages s'il n'y a pas de sélection (pour éviter des bugs d'affichage)
        let hasSelectedWeeks = [...this.Model.weeks.values()].some((value) => value)
        if (!hasSelectedWeeks) {
          this.clearStages()
        }
      }),
      debounce(skipper.timer),
    );

    this.Stream.weeks$.subscribe((event: {key: string, value: boolean}) => {
      let skipLoading = skipper.endSkipStatus(event);

      StagesEvent.onWeekClick.call(this, event.key, event.value, skipLoading).then()
    })
  }

  /**
   * Initialise un observable qui observe les sélections des lieux par l'utilisateur et envoie des requêtes pour
   * charger les stages, avec un petit délai pour éviter l'envoie plusieurs requêtes aux cliques rapides.
   * L'etat de la vue est mise à jour pour afficher le chargement, données, messages. Après l'évènement, les stages
   * sont chargées et les données locales et attributs sont mises à jour.
   * @private
   */
  static initplacesObserver(this: StagesComponent) {
    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE Semaines
    this.Stream.places$ = new Subject<{value: string, checked: boolean}>().pipe(
      tap((v) => {
        // 1. auto filtre
        if (this.Model.isAutoApplyFilters) {
          this.loadingStatus.stage = LOADING;
        }

        StagesEvent.onPlaceClick.call(this, v.value, v.checked)
      }),
      debounceTime(G.REQUEST_DELAY),
    );

    this.Stream.places$.subscribe(() => {
      if (this.Model.isAutoApplyFilters) this.Stream.filteredStages$.next('filterPlace');
    })
  }

  /**
   * initialise un flux qui observe constament les filtres pour charger les stages quand toutes les conditions sont
   * respectés.
   * @private
   */
  static initStagesObserver(this: StagesComponent) {
    let allowedForRunFetch = ['paginator', 'confirm-button', 'start-event'];

    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE STAGES
    this.Stream.filteredStages$ = new Subject<any>().pipe(
      // si les filtres autos sont activées, laisse passé les évènements qui déclenche l'application des filtres comme
      // changer l'age, sélectionner les semaines, les lieux
      filter((value) => (allowedForRunFetch.includes(String(value)) ? true : this.Model.isAutoApplyFilters)),
      tap((value) => {
        this.Model.coursesCalledByButton = allowedForRunFetch.includes(String(value));
        if (value !== 'paginator') {
          this.loadingStatus.stage = LOADING;
          this.paginator.total = undefined;
          this.paginator.cursor = 0;
        }
      }),
      debounceTime(G.REQUEST_DELAY),
      tap((value) => {
        this.clearStages();
        if (value === 'paginator') {
          this.paginator = new Paginator(this.paginator.quantite, this.paginator.cursor, this.paginator.total);
        }
        this.loadingStatus.stage = this.Model.isValidFilter()?LOADING:LOADED;
      }),
      filter(() => this.Model.isValidFilter()),
    );

    this.Stream.filteredStages$.subscribe(() => {
      StagesObserverUtil.fetchFilteredStages.call(this);
    })
  }
//----------------------
// objet subscribe
  /**
   * Génère un objet observeur pour la méthode subscribe, avec l'action à éffectuer au moment de l'appel de l'évènement.
   * @param stagesDates
   * @param resolve
   * @param reject
   * @private
   */
  static getSubscribeWeeks(stagesDates: string[], resolve: () => void, reject: (err:Error) => void) :  Partial<Observer<any>> | ((value: any) => void) {
    return {
      next: (res) => {
        const dates = Array.isArray(res) ? res : [res];
        dates.forEach(date => {
          stagesDates.push(date.toString());
        });
        resolve();
      },
      error: (error) => {
        reject(error);
      }
    };
  }

  /**
   * Génère un objet observeur pour la méthode subscribe, avec l'action à éffectuer au moment de l'appel de l'évènement.
   * @param weeks
   * @param resolve
   * @param reject
   * @private
   */
  static getSubscribePlaces(this: StagesComponent, weeks: Week[], resolve: () => void, reject: (err:Error) => void): Partial<Observer<Object>> | ((value: Object) => void) {
    return {
      next: (res) => {
        /* res => [[weekname, [lieu1, lieu2, lieu3]], [weekname, [lieu1, lieu2, lieu3]]] */
        this.Model.weekPlaces.clear()
        if (Array.isArray(res) && res.length > 0) {
          weeks.forEach(() => {
            let lieux: Set<string> = new Set();
            // const places: string[] = Array.from(new Set(res.map(place => place.toString())));
            res.forEach(record => {
              this.Model.weekPlaces.set(record[0], record[1])
              record[1].forEach((place: string) => lieux.add(place));
            })

            // Ajoute les valeurs à la Map Places
            let localplaces = StagesFiltersLocalStorage.loadFromLocal()[3]||new Map();
            lieux.forEach((place: string) => this.Model.places.set(place, localplaces.get(place) || false));

            // Trie les Lieux
            this.Model.places = new Map([...this.Model.places.entries()].sort());

            this.updatePlacesMultiselect();
          })
        }

        this.updatePlacesMultiselect();
        // this.filteredStages$.next('filterPlace')
        resolve();
      },
      error: (error) => {
        reject(error);
      }
    };
  }

  /**
   * Génère un objet observeur pour la méthode subscribe, avec l'action à éffectuer au moment de l'appel de l'évènement.
   * @param resolve
   * @param reject
   * @private
   */
  static getSubscribeStages(this: StagesComponent, resolve: () => void, reject: (err:Error) => void) :  Partial<Observer<any>> | ((value: any) => void) {
    return {
      next: (res) => {
        let stages: any[] = [res][0][0];
        let total: number = [res][0][1];
        if (res && stages.length > 0) {
          let instances = [res][0][0];
          this.paginator.total = total;
          // création de liste d'objet stages
          this.Model.filteredStages = StagesObserverUtil.getInstances(instances);
        }
        resolve()
      },
      error: (error) => {
        reject(error)
      }
    }
  }

  /**
   * Méthode permetant de créer une liste d'objet Stage servant à l'affichage dans la vue.
   * @param instances
   * @private
   */
  private static getInstances(instances: any) {
    //console.dir(instances);
    return instances.map((jsonItem: any) => {
      let nom = he.decode(jsonItem.NOM);
      let stageCard = new StageCard(
        jsonItem.NUMERO_INSTANCE_STAGES,
        nom,
        Math.round(jsonItem.AGE_MIN) + " - " + Math.round(jsonItem.AGE_MAX) + ' ans',
        jsonItem.LOCALITE,
        jsonItem.DATE_DEBUT + ' - ' + jsonItem.DATE_FIN,
      );

      let startDateParts = jsonItem.DATE_DEBUT.split('-')
      let dateDebut = `${startDateParts[2]}/${startDateParts[1]}/${startDateParts[0]}`;
      let endDateParts = jsonItem.DATE_FIN.split('-')
      let dateFin = `${endDateParts[2]}/${endDateParts[1]}/${endDateParts[0]}`;

      stageCard.Date = `${dateDebut} - ${dateFin}`;
      stageCard.Period = getPeriodeByDate(new Date(jsonItem.DATE_DEBUT))?.periode?.toString() || "non spécifiée";
      stageCard.RemainingSlots = jsonItem.REMAINING_SLOTS;
      stageCard.PrixDemiJournee = jsonItem.PRIX_DEMI_JOURNEE;
      stageCard.isFillForced = jsonItem.FORCER_COMPLET === 1;
      stageCard.isFillForcedAm = jsonItem.FORCER_COMPLET_AM === 1;
      stageCard.isFillForcedPm = jsonItem.FORCER_COMPLET_PM === 1;
      return stageCard;
    });
  }

  /**
   * Sur base de la période, calcule les semaines de le période spécifié à partir de la semaine prochaine. Et sur base de
   * ces semaines, une requête avec les semaines est envoyé à laravel pour s'abonner aux flux qui donnera les semaines
   * des stages disponibles.
   * @param Model
   * @param dataService
   * @param periods période qui conserne les stages
   * @private
   */
  static fetchWeeks(Model: StagesModel, dataService: DataService, periods: string[]): Promise<void> {
    // liste des semaines d'une période à partir de la semaine prochaine
    const weeksOfPeriods = getSelectionableWeeks(periods);
    return new Promise<void>((resolve, reject) => {
      // Si weeksOfPeriods est vide, pas besoin de chercher les stages, le résultat est vide, ça évite les erreurs de undefined
      if (weeksOfPeriods.length === 0) {
        Model.stagesDates = [];
        return resolve();
      }

      let firstWeek = convertDateFormat(weeksOfPeriods[0].startDateString)
      let lastWeek = convertDateFormat(weeksOfPeriods[weeksOfPeriods.length-1].endDateString)

      // requête envoyé vers laravel + abonné au flux pour être à jours sur les semaines.
      dataService.getStagesDates(firstWeek, lastWeek)
        .subscribe(
          StagesObserverUtil.getSubscribeWeeks(Model.stagesDates, resolve, reject));
      // return resolve();
    });
  }

  /**
   * charge les stages sur base des filtres, utilisé par le flux d'évenement stage à la demande UNIQUEMENT.
   * Une requête est envoyé et les données chargées, si les conditions de filtres sont respectés
   */
  private static fetchFilteredStages(this: StagesComponent) {
    // converti la map en liste des valeurs sélectionnées
    const selectedPlaces = Array.from(this.Model.places).filter(([_, value]) => value).map(([key]) => key);
    const selectedWeeks = Array.from(this.Model.weeks).filter(([_, value]) => value).map(([key]) => key);

    // s'il y a au moin un élément sélectionné dans chacun des 2 listes
    if(selectedWeeks.length > 0 && selectedPlaces.length > 0){
      StagesObserverUtil.fetchFilteredStages2.call(this, selectedWeeks, selectedPlaces, this.Model.ageRange);
    } else {
      this.loadingStatus.stage = LOADED;
    }
  }

  //Récupère les instance de stage via api Rest en fonction des filtres
  private static fetchFilteredStages2(this:StagesComponent, semaines: Week[], lieux: string[], ageRange: AgeRange) {
    const weekArr: string[][] = [];
    semaines.forEach(week => {
      weekArr.push(getIntervalDates(week))
    })
    new Promise<void>((resolve, reject) => {
      this.dataService.getFilteredStages(weekArr, lieux, ageRange, this.paginator)
        .subscribe(StagesObserverUtil.getSubscribeStages.call(this, resolve, reject));
    })
      .then(() => {
        this.zone.run(() => {
          this.loadingStatus.stage = LOADED;
        });
      })
      .catch(error => {
        this.loadingStatus.stage = ERROR;
        if (error.name === 'TimeoutError') {
          this.loadingStatus.stage = TIME_OUT;
        }
        if (error instanceof TypeError) {
          this.loadingStatus.stage = NET_ERROR;
        }
      });
  }
}
