import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Week} from "../../../models/Week";

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.css', '../../common/styles/common.css']
})
export class RecordListComponent {
  @Input() values! :Map<Week, boolean>;
  @Input() title: string = 'default title';
  @Input() status! :{message: string, classes: any[], isValid : boolean, isLoading : boolean};
  @Output() onRecordClick = new EventEmitter<{key: any, value: boolean}>();

  protected emptySpaceObjects: number[] = new Array(0); // pour la gestion de l'espace vide (visuel)

  weekDateToString(week: Week): string{
    return `${week.startDateString} - ${week.endDateString}`;
  }

  weekNameToString(week: Week): string{
    return `${week.name}: `;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; innerHeight: any; }; }) {
    const width = event.target.innerWidth - 59;
    const numColumns = Math.floor(width/330)||1;
    const requiredEmptySpaces = Math.floor(numColumns - this.values.size%numColumns)-1;
    this.emptySpaceObjects = new Array(requiredEmptySpaces)
  }
}
